import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import './style.css';

function PaymentMethod() {
  const [formData, setFormData] = useState({
      user_id: '',
      first: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      mobile: '',
      email: '',
      storename: '',
    });
    const getAddress = async (user_id) =>{
    var user = JSON.parse(localStorage.getItem('userdata'));
    try {
      const response = await fetch('https://api.indiantrolley.com/sellers/get_address.php?user_id='+user_id, {
        method: 'GET'
      });

      if (response.ok) {
        const responseData = await response.json();
        if(responseData.status){
          setFormData({
            ...formData,
            user_id:user_id,
            first: responseData?.category[0]?.first,
            mobile: responseData?.category[0]?.mobile,
            email: responseData?.category[0]?.email,
            address1: responseData?.category[0]?.address1,
            address2: responseData?.category[0]?.address2,
            city: responseData?.category[0]?.city,
            state: responseData?.category[0]?.states,
            zip: responseData?.category[0]?.zip,
            storename: responseData?.category[0]?.storename
          });
          console.log(formData);
        }
      } else {
        console.log('Failed to send data');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();

      const response = await fetch('https://api.indiantrolley.com/sellers/add_address.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      console.log(result);
      window.location.href = "/dashboard";
      alert("Address Selected")
    };
    useEffect(()=>{
      const username = localStorage.getItem('user');
      setFormData({ ...formData, ['user_id']:JSON.parse(username).id})
      // alert(JSON.parse(username).id);
      getAddress(JSON.parse(username).id)
    },[]);
  return (
    <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Store Address
        </MDTypography>
      </MDBox>
      <MDBox>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend>Informations</legend>

            <label htmlFor="nom">Full Name:</label>
            <input type="text" id="nom" name="first" value={formData.name} required onChange={handleChange}/>

            <label htmlFor="prenom">Mobile:</label>
            <input type="text" id="prenom" name="mobile" value={formData.mobile} required onChange={handleChange} />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} required onChange={handleChange} />

          </fieldset>

          <fieldset>
            <legend>Details Adresse</legend>

            <label htmlFor="adresse1">Adresse1:</label>
            <input type="text" id="adresse1" name="address1" required value={formData.address1} onChange={handleChange} />

            <label htmlFor="adresse2">Adresse2:</label>
            <input type="text" id="adresse2" name="address2" required value={formData.address2} onChange={handleChange} />

            <label htmlFor="ville">City:</label>
            <input type="text" id="ville" name="city" value={formData.city} required onChange={handleChange} />

            <label htmlFor="ville">State:</label>
            <input type="text" id="ville" name="state" value={formData.state} required onChange={handleChange} />

            <label htmlFor="code_postal">Code Postal:</label>
            <input type="text" id="code_postal" name="zip" value={formData.zip} required onChange={handleChange} />

          </fieldset>

          <fieldset>
            <legend>Store Informations</legend>

            <label htmlFor="adresse">Store Name:</label>
            <input type="text" name="storename" value={formData.storename} required onChange={handleChange} />
          </fieldset>

          <input type="submit" value="Submit" />
        </form>
      </MDBox>
    </Card>
  );
}

export default PaymentMethod;
