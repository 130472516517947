import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";

import { styled } from "@mui/material/styles";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import AddCategory from "./AddCategory";
import { useState, useEffect } from "react";

function Categorys() {
  const [isOpen, setIsOpen] = useState(false);
  const { columns, rows } = authorsTableData();
  const [catData, setCatData] = useState([]);
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const getCartItems = async () => {

        try {
          await fetch('https://api.indiantrolley.com/sellers/get_subcategory_all.php', {
            method: "GET",
          }).then((res) => {
            res.json().then((response) => {
              if (response?.status) {
                console.log('see dataa:', response?.subcategories);
                setCatData(response?.subcategories || []);
              } else {
                console.error(response?.message);
              }
            })
          });
        }
        catch (err) {console.error('Something went wrong!'); }
      }
      useEffect(() => {
        getCartItems();
        window.scrollTo(0, 0);
      }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Category
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  <AddCategory
                  shouldOpen={false}
                  />
                </div>
                <Grid container spacing={1}>
                  {catData.map((item, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <Card sx={{ maxWidth: 200 }} key={index}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={item.image?"https://www.indiantrolley.com/images/large/"+item.image:'https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books-illustration_23-2149341898.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1708473600&semt=sph'}
                              alt={item.name}
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6" fontSize={13} lineHeight={1.2} component="div">
                                {item.name}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>

                <p>
                  <br />
                </p>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <p>
          <br></br>
        </p>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Categorys;
