/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const [viewProduct, setViewProduct] = useState({});
  const [ordered, setOrdered] = useState(false);
  const [shiped, setShiped] = useState(false);
  const [packed, setPacked] = useState(false);
  const [onTheWay, setOnTheWay] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    const jsonData = localStorage.getItem('view_product');
    if (jsonData) {
      const userData = JSON.parse(jsonData);
      setViewProduct(userData);
    }
    handleTransparentSidenav(viewProduct?.status,false);
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const getCartItems = async (status) => {

        try {
          await fetch('https://api.indiantrolley.com/sellers/update-status.php?ordercode='+viewProduct?.id+'&status='+status, {
            method: "GET",
          }).then((res) => {
          });
        }
        catch (err) {console.error('Something went wrong!'); }
  }
  const handleTransparentSidenav = (status,type = true) => {
    if(type){
      getCartItems(status);
    }
    viewProduct.status = status;
    const jsonData = JSON.stringify(viewProduct);
    localStorage.setItem('view_product', jsonData);
    if (status=="ORDERED") {setOrdered(true);setShiped(false);setOnTheWay(false);setPacked(false);setDelivered(false)} 
    else if (status=="SHIPED") {setShiped(true);setOrdered(false);setOnTheWay(false);setPacked(false);setDelivered(false)} 
    else if (status=="ONTHEWAY") {setOnTheWay(true);setShiped(false);setOrdered(false);setPacked(false);setDelivered(false)}
    else if (status=="PAK") {setPacked(true);setOnTheWay(false);setShiped(false);setOrdered(false);setDelivered(false)}
    else if (status=="DELIVERED") {setDelivered(true);setPacked(false);setOnTheWay(false);setShiped(false);setOrdered(false)}
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };

  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5" onClick={()=>{window.open("https://www.indiantrolley.com/product/"+viewProduct?.slug, '_blank');}}>{viewProduct?.ordercode}</MDTypography>
          <MDTypography variant="body2" color="text">
          </MDTypography>
          <MDTypography variant="body2" color="text">
            {viewProduct?.pay_type}
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

        <MDBox mt={3} lineHeight={1}>
          <MDTypography variant="h6">Sidenav Type</MDTypography>
          <MDTypography variant="button" color="text">
            Update Order Status
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={()=>handleTransparentSidenav("ORDERED")}
                disabled={disabled}
                fullWidth
                sx={
                  ordered && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                ORDERED
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={()=>handleTransparentSidenav("PAK")}
              disabled={disabled}
              fullWidth
              sx={
                packed && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
              }
            >
              PACKED
            </MDButton>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={()=>handleTransparentSidenav("SHIPED")}
              disabled={disabled}
              fullWidth
              sx={
                shiped && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
              }
            >
              SHIPED
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={()=>handleTransparentSidenav("ONTHEWAY")}
                disabled={disabled}
                fullWidth
                sx={
                  onTheWay && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                ON THE WAY
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={()=>handleTransparentSidenav("DELIVERED")}
              disabled={disabled}
              fullWidth
              sx={
                delivered && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
              }
            >
              DELIVERED
            </MDButton>
          </MDBox>
        </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
