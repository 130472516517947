import React from 'react';
import { FormLayout } from '@hilla/react-components/FormLayout.js';
import { TextField } from '@hilla/react-components/TextField.js';

function App() {
  const responsiveSteps = [
    { minWidth: '0', columns: 1 },
    { minWidth: '500px', columns: 2 },
  ];

  return (
    <FormLayout responsiveSteps={responsiveSteps}>
      <TextField label="First name" />
      <TextField label="Last name" />
      <TextField {...{ colspan: 2 }} label="Shop Name" />
      <TextField label="Address 1" />
      <TextField label="Address 2" />
      <TextField label="Zip" />
      <TextField label="Pincode" />
    </FormLayout>
  );
}

export default App;