import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { useState, useEffect } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate } from "react-router-dom";

export default function data(ViewOrder) {
  // console.log(ViewOrder());
  const router = useNavigate();
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const getStatusColor = (status) => {
    switch (status) {
      case 'ORDERED':
        return 'danger'; // Change to the color you prefer for ORDERED status
      case 'PACKED':
        return 'warning'; // Change to the color you prefer for PACKED status
      case 'SHIPPED':
        return 'dark'; // Change to the color you prefer for SHIPPED status
      case 'ONTHEWAY':
        return 'info'; // Change to the color you prefer for ONTHEWAY status
      case 'DELIVERED':
        return 'success'; // Change successto the color you prefer for DELIVERED status
      default:
        return 'dark'; // Default color for unknown status
    }
  };
  const color = getStatusColor(status);
  useEffect(() => {
    let ordersList = [];
    fetch('https://api.indiantrolley.com/sellers/order-list.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        // Assuming data is an array of orders
        data?.data?.forEach(function(item) {
          let row = {
            ordercode: <p>{item.ordercode}</p>,
            status: (
              <MDBox ml={-1}>
                <MDBadge badgeContent={item.status} color={getStatusColor(item.status)} variant="gradient" size="sm" />
              </MDBox>
            ),
            user_id: (
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {item.created_at}
              </MDTypography>
            ),
            id: (
              <MDTypography component="a" onClick={()=>router("/order-list/"+item.ordercode)} variant="caption" color="text" fontWeight="medium">
                View
              </MDTypography>
            ),
          };
          ordersList.push(row);
        });
        setOrders(ordersList);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);

  return {
    columns: [
      { Header: "Order Code", accessor: "ordercode", width: "45%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "delivery Date", accessor: "user_id", align: "center" },
      { Header: "action", accessor: "id", align: "center" },
    ],

    rows: orders,
  };
}
