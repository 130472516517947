import React, { useState, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { FileUploader } from "react-drag-drop-files";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import "rsuite/dist/rsuite.min.css";
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';

ProductRow.propTypes = {
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
};

const fileTypes = ["JPG", "PNG"];

function AddProduct() {
    const [open, setOpen] = React.useState(false);
    const [openWithHeader, setOpenWithHeader] = React.useState(false);
    const [file, setFile] = useState(null);
    const [catData, setCatData] = useState([]);
    const [name, setName] = useState('');
    const [des, setDes] = useState('');
    const [cat, setCat] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [qty, setQty] = useState(1);
    const [size4, setSize4] =useState(false);
    const [size5, setSize5] =useState(false);
    const [size6, setSize6] =useState(false);
    const [size7, setSize7] =useState(false);
    const [size8, setSize8] =useState(false);
    const [size9, setSize9] =useState(false);
    const [size10, setSize10] =useState(false);
    const [sizeS, setSizeS] =useState(false);
    const [sizeM, setSizeM] =useState(false);
    const [sizeL, setSizeL] =useState(false);
    const [sizeXL, setSizeXL] =useState(false);
    const [sizeXXL, setSizeXXL] =useState(false);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        cat: '',
        discount: '',
        qty: '',
        description: '',
        images: [], // Array to store image files
        // Add other form fields here
      });
    const handleImageChange = (files) => {
        setFormData({ ...formData, images: files });
      };

    const [rows, setRows] = useState([]);
    const [formValues, setFormValues] = useState([]);

    const handleAddRow = () => {
        setRows([
            ...rows,
            <ProductRow key={rows.length} onRemove={() => handleRemoveRow(rows.length)} onUpdate={handleUpdateRow} />,
        ]);
    };

    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const handleUpdateRow = (product) => {
        // Update the formValues array with the current product values
        setFormValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[rows.length] = product;
            return newValues;
        });
    };

    const handleSubmit = async() => {
        try {
        setOpen(false);
        const formDataWithImages = new FormData();
        formDataWithImages.append('name', name);
        formDataWithImages.append('price', price);
        formDataWithImages.append('discount', discount);
        formDataWithImages.append('cat', cat);
        formDataWithImages.append('qty', qty);
        formDataWithImages.append('description', des);
        formDataWithImages.append('seller', 'Indian Trolley');
        var size = "";
        if(size4){
            size = size + "|" + size4;
        }if(size5){
            size = size + "|" + size5;
        }if(size6){
            size = size + "|" + size6;
        }if(size7){
            size = size + "|" + size7;
        }if(size8){
            size = size + "|" + size8;
        }if(size9){
            size = size + "|" + size9;
        }if(size10){
            size = size + "|" + size10;
        }if(sizeS){
            size = size + "|" + sizeS;
        }if(sizeM){
            size = size + "|" + sizeM;
        }if(sizeL){
            size = size + "|" + sizeL;
        }if(sizeXL){
            size = size + "|" + sizeXL;
        }if(sizeXXL){
            size = size + "|" + sizeXXL;
        }
        const storedUser = localStorage.getItem('user');
        const userData = JSON.parse(storedUser);
        formDataWithImages.append('size', size.slice(1,));
        formDataWithImages.append('uploader', userData.id);
        if (formData.images && formData.images.length > 0) {
            // Convert formData.images to an array
            const imageArray = Array.from(formData.images);
            
            // Iterate over the array and append each image to formDataWithImages
            imageArray.forEach((file, index) => {
                formDataWithImages.append(`images[]`, file);
            });
        } else {
            // Handle the case when no images are selected/uploaded
            console.error('No images selected/uploaded');
        }   

          const response = await fetch('https://api.indiantrolley.com/sellers/insert_product.php', {
            method: 'POST',
            body: formDataWithImages,
          });
          const data = await response.json();
          console.log(data);
          if(data.status){
            setOpen(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };
      const getCartItems = async () => {

        try {
          await fetch('https://api.indiantrolley.com/sellers/get_subcategory_all.php', {
            method: "GET",
          }).then((res) => {
            res.json().then((response) => {
              if (response?.status) {
                console.log('see dataa:', response?.subcategories);
                setCatData(response?.subcategories || []);
              } else {
                console.error(response?.message);
              }
            })
          });
        }
        catch (err) {console.error('Something went wrong!'); }
      }
      useEffect(() => {
        getCartItems();
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                style={{ backgroundColor: "black" }}
                startIcon={<AddIcon color="white" />}
            >
                <span style={{ color: "white" }}>Add Product</span>
            </Button>

            <Drawer
                onOpen={handleAddRow}
                open={open} style={{ zIndex: 3000 }}
                onClose={() =>{setRows([]); setOpen(false)}}
            >
                <Drawer.Body>
                    <Typography variant="h3" gutterBottom>
                        Add Product
                    </Typography>
                    <p><br /></p>
                    <Typography variant="h6" gutterBottom>
                        Product Images
                    </Typography>
                    <FileUploader handleChange={handleImageChange} name="images" multiple />
                    <p><br /></p>

                    <TextField
                        required
                        onChange={(e)=>setName(e.target.value)}
                        id="outlined-required"
                        label="Product Name"
                        fullWidth={true}
                    />

                    <p><br /></p>
                    <TextField
                    onChange={(e)=>setDes(e.target.value)}
                        id="standard-multiline-static"
                        label="Product Description"
                        multiline
                        rows={4}
                        variant="standard"
                        fullWidth={true}
                    />

                    <p><br /></p>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={catData}
                        getOptionLabel={(option) => option.name}
                        fullWidth={true}
                        onChange={(e, selectedOption) => setCat(selectedOption.id)}
                        // value={1} // Set the default value here
                        renderInput={(params) => <TextField {...params} label="Sub-category" />}
                    />
                    <p><br /></p>
                    <TextField
                        required
                        id="price"
                        label="Price"
                        fullWidth={false}
                        onChange={(e)=>setPrice(e.target.value)}
                        type="number"
                        inputProps={{
                            min: 0, // optionally, set minimum value
                            step: 0.01 // optionally, set step value
                        }}
                    />

                    <TextField
                        required
                        id="discount_price"
                        label="Discount Price"
                        fullWidth={false}
                        onChange={(e)=>setDiscount(e.target.value)}
                        type="number"
                        inputProps={{
                            min: 0, // optionally, set minimum value
                            step: 0.01 // optionally, set step value
                        }}
                    />
                    <p><br /></p>
                    <TextField
                        required
                        id="qty"
                        label="Quantity"
                        fullWidth={false}
                        type="number"
                        onChange={(e)=>setQty(e.target.value)}
                        inputProps={{
                            min: 1, // optionally, set minimum value
                            step: 1 // optionally, set step value
                        }}
                    />
                    <p><br /></p>
                    <FormLabel component="legend">Size for Shoes</FormLabel>
                    <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="4"
                      control={<Checkbox />}
                      label="4"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize4(value.target.value)}
                    />

                    <FormControlLabel
                      value="5"
                      control={<Checkbox />}
                      label="5"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize5(value.target.value)}
                    />
                                        <FormControlLabel
                      value="6"
                      control={<Checkbox />}
                      label="6"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize6(value.target.value)}
                    />
                                        <FormControlLabel
                      value="7"
                      control={<Checkbox />}
                      label="7"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize7(value.target.value)}
                    />
                                        <FormControlLabel
                      value="8"
                      control={<Checkbox />}
                      label="8"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize8(value.target.value)}
                    />
                                        <FormControlLabel
                      value="9"
                      control={<Checkbox />}
                      label="9"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize9(value.target.value)}
                    />
                                        <FormControlLabel
                      value="10"
                      control={<Checkbox />}
                      label="10"
                      labelPlacement="bottom"
                      onChange={(value)=>setSize10(value.target.value)}
                    />
                    </FormGroup>

                    <FormLabel component="legend">Size for Cloths</FormLabel>
                    <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="S"
                      control={<Checkbox />}
                      label="S"
                      labelPlacement="bottom"
                      onChange={(value)=>setSizeS(value.target.value)}
                    />

                    <FormControlLabel
                      value="M"
                      control={<Checkbox />}
                      label="M"
                      labelPlacement="bottom"
                      onChange={(value)=>setSizeM(value.target.value)}
                    />
                                        <FormControlLabel
                      value="L"
                      control={<Checkbox />}
                      label="L"
                      labelPlacement="bottom"
                      onChange={(value)=>setSizeL(value.target.value)}
                    />
                                        <FormControlLabel
                      value="XL"
                      control={<Checkbox />}
                      label="XL"
                      labelPlacement="bottom"
                      onChange={(value)=>setSizeXL(value.target.value)}
                    />
                                        <FormControlLabel
                      value="XXL"
                      control={<Checkbox />}
                      label="XXL"
                      labelPlacement="bottom"
                      onChange={(value)=>setSizeXXL(value.target.value)}
                    />
                    </FormGroup>
                    <p><br /></p>
                    <div>
              {/*          {rows.map((row, index) => (
                            <div key={index}>{row}</div>
                        ))}*/}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>alert("Seo Work is Pendding")}
                            startIcon={<AddIcon />}>
                            Advance Options
                        </Button>

                        <p><br /></p>
                        <p><br /></p>
                        <Button block variant="contained" color="primary" onClick={()=>{handleSubmit()}}>
                            Add Product
                        </Button>
                    </div>
                </Drawer.Body>
            </Drawer>
        </>
    )
}


function ProductRow({ onRemove, onUpdate }) {
    const [product, setProduct] = useState({
        sku: '',
        gst: '',
        size: '',
        price: '',
        discountedPrice: '',
        inStock: '',
    });

    const handleChange = (field) => (event) => {
        setProduct({
            ...product,
            [field]: event.target.value,
        });
    };

    const handleRemove = () => {
        onRemove();
    };

    // Pass the product values to the parent on update
    React.useEffect(() => {
        onUpdate(product);
    }, [product, onUpdate]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <TextField label="SKU" value={product.sku} onChange={handleChange('sku')} />
            <TextField label="GST" value={product.gst} onChange={handleChange('gst')} />
            <TextField label="Size" value={product.size} onChange={handleChange('size')} />
            <TextField label="Price" value={product.price} onChange={handleChange('price')} />
            <TextField
                label="Discounted Price"
                value={product.discountedPrice}
                onChange={handleChange('discountedPrice')}
            />
            <TextField label="In Stock" value={product.inStock} onChange={handleChange('inStock')} />
            <div
                onClick={handleRemove}
                style={{
                    cursor: 'pointer',
                }}>
                <CloseIcon />
            </div>
        </div>
    );
}

export default AddProduct;
