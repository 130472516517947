import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";

import { styled } from "@mui/material/styles";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import AddProduct from "./AddProduct";
import { useState, useEffect } from "react";

function Products() {
  const [isOpen, setIsOpen] = useState(false);
  const { columns, rows } = authorsTableData();
  const [productData, setProductData] = useState([]);
  const { columns: pColumns, rows: pRows } = projectsTableData();
    const getCartItems = async () => {
      const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            try {
              await fetch('https://api.indiantrolley.com/sellers/get_product_list_all.php?uploader='+userData.id, {
                method: "GET",
              }).then((res) => {
                res.json().then((response) => {
                  if (response?.status) {
                    console.log('see dataa:', response?.product);
                    setProductData(response?.product || []);
                  } else {
                    console.error(response?.message);
                  }
                })
              });
            }
            catch (err) {console.error('Something went wrong!'); }
          }
        }
      useEffect(() => {
        getCartItems();
        window.scrollTo(0, 0);
      }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Products
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  <AddProduct
                  shouldOpen={false}
                  />
                </div>
                <Grid container spacing={1}>
                  {productData.map((item, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <Card sx={{ maxWidth: 200 }} key={index}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={item.images[0]?"https://www.indiantrolley.com/images/large/"+item.images[0].image_url:'https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg'}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6" fontSize={13} lineHeight={1.2} component="div">
                                {item.product_name.slice(0, 13)+'...'}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                ₹ {item.attr[0]?item.attr[0].price:'0'}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>

                <p>
                  <br />
                </p>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <p>
          <br></br>
        </p>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Products;
