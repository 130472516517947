import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { useState, useEffect } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useParams } from 'react-router-dom';

export default function data() {

  const { orderCode } = useParams();
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState([]);
  const [error, setError] = useState(null);
  
  const viewProduct = (id)=>{
    for (var i = 0; i < order.length; i++) {
      if (id == order[i].id) {
        const jsonData = JSON.stringify(order[i]);
        localStorage.setItem('view_product', jsonData);
      }
    }
    document.getElementsByClassName('MuiBox-root css-fxid9l')[0].click();
  }  
    useEffect(() => {
    let ordersList = [];
    fetch('https://api.indiantrolley.com/sellers/get-order-product.php?ordercode='+orderCode)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        setOrder(data?.data);
        // Assuming data is an array of orders
        data?.data?.forEach(function(item) {
          let rowss = {
            name: (
              <MDBox ml={-1}>
              <a href={"https://www.indiantrolley.com/product/"+item.slug} target="blank_">
                <MDBadge badgeContent={item.name} variant="gradient" size="sm" />
              </a>
              </MDBox>
            ),
            qty: (
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {item.qty}
              </MDTypography>
            ),
            price: (
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {item.price}
              </MDTypography>
            ),
            id: (
              <MDTypography onClick={()=>{
                viewProduct(item.id);
              }} component="a" variant="caption" color="text" fontWeight="medium">
                {item.status}
              </MDTypography>
            ),
          };
          ordersList.push(rowss);
        });
        setOrders(ordersList);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);
  return {
    columns: [
      { Header: "Product Title", accessor: "name", width: "45%", align: "left" },
      { Header: "qty", accessor: "qty", align: "center" },
      { Header: "price", accessor: "price", align: "center" },
      { Header: "id", accessor: "id", align: "center" },
    ],

    rows: orders,
  };
}
