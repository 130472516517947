import React, { useState, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { FileUploader } from "react-drag-drop-files";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import "rsuite/dist/rsuite.min.css";
import PropTypes from 'prop-types';

const fileTypes = ["JPG", "PNG"];



function AddCategory() {
    const [open, setOpen] = React.useState(false);
    const [openWithHeader, setOpenWithHeader] = React.useState(false);
    const [mainCategoryChecked, setMainCategoryChecked] = useState(false);
    const [subCategoryChecked, setSubCategoryChecked] = useState(false);
    const [file, setFile] = useState(null);
    const [catData, setCatData] = useState([]);
    const [name, setName] = useState('');
    const [des, setDes] = useState('');
    const [type, setType] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        subTitle:'',
        description: '',
        images: [], // Array to store image files
        // Add other form fields here
      });
    const handleImageChange = (files) => {
        setFormData({ ...formData, images: files });
      };

    const handleMainCategoryChange = (event) => {
        setMainCategoryChecked(event.target.checked);
        setType('main');
        if (event.target.checked) {
            setSubCategoryChecked(false);
        }
    };

    const handleSubCategoryChange = (event) => {
        setSubCategoryChecked(event.target.checked);
        setType('sub');
        if (event.target.checked) {
            setMainCategoryChecked(false);
        }
    };

    const [rows, setRows] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const handleUpdateRow = (product) => {
        // Update the formValues array with the current product values
        setFormValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[rows.length] = product;
            return newValues;
        });
    };

    const handleSubmit = async() => {
        try {
          const formDataWithImages = new FormData();
            formDataWithImages.append('name', name);
            formDataWithImages.append('type', type);
            formDataWithImages.append('subTitle', subTitle);
            formDataWithImages.append('description', des);
            if (formData.images && formData.images.length > 0) {
                // Convert formData.images to an array
                const imageArray = Array.from(formData.images);
                
                // Iterate over the array and append each image to formDataWithImages
                imageArray.forEach((file, index) => {
                    formDataWithImages.append(`images[]`, file);
                });
            } else {
                // Handle the case when no images are selected/uploaded
                console.error('No images selected/uploaded');
            }

              const response = await fetch('https://api.indiantrolley.com/sellers/insert_category.php', {
                method: 'POST',
                body: formDataWithImages,
              });
              const data = await response.json();
              console.log(data);
              if(data.status){
                // window.location.href = window.location.pathname;
              }
            } catch (error) {
              console.error('Error:', error);
            }
    };

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                style={{ backgroundColor: "black" }}
                startIcon={<AddIcon color="white" />}
            >
                <span style={{ color: "white" }}>Add Category</span>
            </Button>

            <Drawer
                open={open} style={{ zIndex: 3000 }}
                onClose={() =>{setRows([]); setOpen(false)}}
            >
                <Drawer.Body>
                    <Typography variant="h3" gutterBottom>
                        Add Category
                    </Typography>
                    <p><br /></p>
                    <Typography variant="h6" gutterBottom>
                        Category Images
                    </Typography>
                    <FileUploader handleChange={handleImageChange} name="images"/>
                    <p><br /></p>

                    <TextField
                        required
                        id="outlined-required"
                        label="Category Name"
                        fullWidth={true}
                        onChange={(e)=>setName(e.target.value)}
                    />
                    <p><br /></p>
                    <TextField
                        required
                        id="outlined-required"
                        label="Category Sub Title"
                        fullWidth={true}
                        onChange={(e)=>setSubTitle(e.target.value)}
                    />

                    <p><br /></p>
                    <TextField
                        id="standard-multiline-static"
                        label="Category Description"
                        multiline
                        rows={4}
                        variant="standard"
                        fullWidth={true}
                         onChange={(e)=>setDes(e.target.value)}
                    />

                    <p><br /></p>
                    <div>
                        <Checkbox
                            checked={mainCategoryChecked}
                            onChange={handleMainCategoryChange}
                            inputProps={{ 'aria-label': 'Main category checkbox' }}
                        />
                        <label>Main Category</label>
                    </div>
                    <div>
                        <Checkbox
                            checked={subCategoryChecked}
                            onChange={handleSubCategoryChange}
                            inputProps={{ 'aria-label': 'Subcategory checkbox' }}
                        />
                        <label>Sub Category</label>
                    </div>

                    <p><br /></p>
                    <div>
                        <p><br /></p>
                        <Button block variant="contained" color="primary" onClick={handleSubmit}>
                            Add Category
                        </Button>
                    </div>
                </Drawer.Body>
            </Drawer>
        </>
    )
}
export default AddCategory;
